* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/********************
*** CSS variables ***
********************/

:root {
    --menu-height: 126px;
    --menu-fontSize: 15px;
}

/*************
*** HEADER ***
*************/
.menu-navbar {
    margin-bottom:30px;
    padding-top:0px;
    background-color:#fff;
    vertical-align: middle;
}

.logo {
    width: auto;
    border-style: none;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}
.navbar-brand {
    padding-top: 15px;
    padding-bottom: 0.6rem;
}

@media only screen and (max-width: 1199px) {
    .logo-wrapper {
        margin-right: 0;
        padding:20px 30px 0px;
    }
    .menu-navbar{
        padding: 0;
    }
    .toggle-menu {
        border: none;
        padding:0px 30px 10px;
        font-size:1em;
    }
    .toggle-menu:focus {  /* remove orange outline on focus */
        border: none;
        outline: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent; 
    }
    #navbar-nav{
        overflow-y: scroll;
    }
    .menu .nav-item {
        padding: 0 !important;
    }
    .menu .nav-item a{
        font-family: "Source Sans Pro", Arial, Helvetica, sans-serif !important;
        font-weight: 300 !important;
        font-size: 21px !important;
        letter-spacing: 0px;
        font-style: normal; 
        height: 55px;
        background-color: #37507a;
        border-color: rgba(255,255,255,0);
        padding: 12px 0px 0px 30px !important;
        font-size: 21px;
        letter-spacing: 0px;
        color: #ffffff !important;
        border: none !important;
    }
    
    .menu .nav-item a:hover, .menu .nav-item a.active {
        background-color: #35baf2;
        color: #fff !important;
    }
    
}



.menu .nav-item {
    padding-right: 25px;
}
.menu .nav-item a {
    font-family: Roboto, "Arial Black", Gadget, sans-serif;
    font-weight: 500;
    padding-top:50px;
    border-top: 3px solid #fff;
    color: #1b3763;
    cursor: pointer;
}
@media only screen and (min-width: 1200px) {
    
    .menu {
        height:var(--menu-height);
        vertical-align: middle;
    }
    .menu .nav-item a { 
        font-family: Roboto, "Arial Black", Gadget, sans-serif !important;
        font-weight: 500 !important;
        color: #1b3763 !important;
        font-size: 15px;
        padding-top: calc((var(--menu-height) - var(--menu-fontSize))/2);
    }
    .menu .nav-item a:hover, .menu .nav-item a.active{
        border-top: 3px solid #c15527;
        color: #c15527 !important;
    }
}

.navbar-shadow {
    box-shadow: rgba(0,0,0,.117647) 0 1px 3px;
}



/***************
*** OVERVIEW ***
***************/

#overview {
    padding-top: 60px;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: 300;
    letter-spacing: 0px;
    font-style: normal;
    color: #515b69;
}
#overview .row {
    padding-top: 2%;
    padding-bottom: 2%;
}

#overview .overview-general-text {
    padding: 20px;
    margin-right: 6%;
}

#overview .overview-general h2 {
    font-size: 40px;
    line-height: 1.2;
}
#overview .overview-general h3 {
    font-size: 32px;
    line-height: 1.25;
}
#overview .overview-general h1 {
    font-size: 50px;
    line-height: 1.16;
}
#overview .overview-detailed {
    text-align: center;
    background-color: #f4f6f8;
    background-image: url("../../assets/images/light_gradient_bg.jpg");
}
.overview-detailed-text-wrapper {
    margin-top: 65px;
    margin-bottom: 15px;
}
#overview .overview-detailed p {
    font-size: 22px;
    line-height: 1.68;
    color: #747474;
}
#overview .overview-detailed{
    padding: 0px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
}

.overview-detailed .fontawesome-icon {
    border-color: #ffffff;
    border-width: 0px;
    background-color: #ffffff;
    box-sizing: content-box;
    height: 88px;
    width: 88px;
    line-height: 88px;
    border-radius: 50%;
    color: #35baf2;
    font-size: 44px;
    margin-bottom: 15px;
}
.overview-detailed h2 {
    font-size: 28px;
    line-height: 1.18;
}

.overview-detailed h6{
    font-size: 18px;
    line-height: 1.44;
    margin-top: 2em;
    margin-bottom: 2em;
}
.overview-detailed .col {
    padding-left: 30px;
    padding-right: 30px;
}
.text-block {
    padding: 30px;
}
.text-block:hover .fontawesome-icon {
    background-color: #ffa93a;
    border-color: #ffa93a;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse 2s ease-out;
    transform: scale(0.9);
}
@keyframes pulse {
    0% {
        
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.7), 0px 0px 30px 30px rgba(255, 169, 58, 0.7);
    }
    85% {
        
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0), 0px 0px 30px 25px rgba(255, 255, 255, 0.7) ;
    }
    100% {
        
        box-shadow: 0 0 0 30px rgba(255, 255, 255, 0), 0 0 30px 30px rgba(255, 255, 255, 0);
    }
}

/***************
*** SERVICES ***
***************/
#services {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: 300;
    letter-spacing: 0px;
}

#services .services-text-block{
    background-color: rgb(86, 112, 156);
    padding: 7%;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 797px;
    height: auto;
}
#services .services-text-block.dark-blue {
    background-color: rgb(55, 80, 122);
}
#services .services-text-block h3 {
    color: #fff;
    font-size: 32px;
    text-align: center;
    line-height: 1.25;
}
#services .services-text-block p{
    color: #bac5d0;
    font-size: 22px;
}
#services .services-text-block img {
    transition: all 0.7s ease-in-out;
}
#services .services-text-block img:hover {
    transform: scale(1.1);
}

#services .services-additional {
    background-color: rgba(255,255,255,0);
    background-image: url(../../assets/images/bg_feat_section_one.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 25%;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-left:7%;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-color: #eae9e9;
    border-top-style: solid;
    border-bottom-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #fff;   
}

#services .services-additional .services-additional-text {
    padding: 30px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
}
#services .services-additional h1{
    font-size: 50px;
    
}
#services .services-additional p {
    font-size: 22px;
}

/***************
***OUR-PEOPLE***
***************/

#our-people {
    padding-top: 5%;
    padding-bottom: 5%;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.16;
    letter-spacing: 0px;
    font-style: normal;
}
#our-people .leadership-text {
    padding-left: 7%;
    padding-right: 7%;
}
#our-people .leadership-text h1{
    font-size: 50px;
    line-height: 1.16;
    color: #515b69;
}
#our-people .leadership-text p {
    font-size: 22px;
    line-height: 1.68;
    color: #7f8795;
    
}
#our-people .leadership-people {
    color: #515b69;
}
.fa-linkedin, .fa-envelope, .fa-twitter {
    color: #ffffff;
    background-color: #8c95a3;
    border-color: #8c95a3;
    border-radius: 4px;
    font-size: 18px !important;
    padding: 12px;
}
.fa-linkedin {
    padding: 12px 13px;
}
.leadership-name, .team-name {
    font-size: 28px;
}
.leadership-social a{
    margin: 0 5px 10px;
}
.leadership-social a:hover{
    opacity: 0.8;
}
.leadership-description, .team-description p {
    font-size: 22px;
    color: #7f8795;
    line-height: 1.68;
}

.team-description h1{
    font-size: 50px;
    color: #515b69;
}

@media only screen and (max-width: 1200px) {
    .team-description{
        padding-left: 7%;
        padding-right: 7%;
    }
}

.team-name {
    color: #515b69;
}

/***********
*** JOBS ***
***********/

#jobs {
    background-color: #f4f6f8;
    background-position: left top;
    background-repeat: no-repeat;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-color: #eae9e9;
    border-top-style: solid;
    border-bottom-style: solid;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.16;
    letter-spacing: 0px;
    font-style: normal;
}
#jobs h1{
    font-size: 50px;
    color: #515b69;
}

#jobs p {
    font-size: 22px;
    color: #7f8795;
}
#jobs .jobs-list {
    list-style: none;
    line-height: 1.68;
}
#jobs .job-title {
    font-weight: 700;
    font-size: 22px;
    color: #7f8795;
}

#jobs .job-description ul {
    list-style: circle inside;
    font-size: 22px;
    color: #7f8795;
}
/* #jobs .job-description ul li {
    
} */

/**************
****CONTACT****
**************/

#contact {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: 300;
    letter-spacing: 0px;
    font-style: normal;
    font-size:22px;
    color: #7f8795;
    line-height: 1.68;
}
#contact button {
    background: #72849c;
    color: #ffffff;
    border-color: #ffffff;
    padding: 17px 40px;
    line-height: 21px;
    font-size: 18px;
    font-weight: 600;
    transition: all .2s;
}
#contact button:hover {
    background: #35baf2;
    color: #ffffff;
    border-color: #ffffff;
}

/*************
****FOOTER****
*************/

.parallax{
    background-image: url("../../assets/images/footer-image01-1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 25%;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-left: 30px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-color: #eae9e9;
    border-top-style: solid;
    border-bottom-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
}
@media only screen and (max-device-width: 1200px) {
    .parallax {
      background-attachment: scroll;
    }
  }
.footer {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: 300;
    letter-spacing: 0px;
    font-style: normal;
}
.footer .parallax {
    color: #fff;
}
.footer .parallax h1{
    font-size: 50px;
    line-height: 1.16;
}
.footer .parallax p{
    font-size: 22px;
    line-height: 1.68;
}
.footer .footer-copyright {
    border-top: 1px solid rgba(255,255,255,0);
    background-color: #ecf0f5;
    color: #7f8795;
    font-size: 16px;
}
.footer .footer-copyright i{
    background-color: #bcc5d1;
    border-color: #bcc5d1;
    border-radius: 4px;
}
.footer .footer-copyright a{
    color: #72849c;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: linear;
}
.footer .footer-copyright a:hover{
    color: #57c5f3;
    text-decoration: none;
}
.footer .footer-social a:hover {
    opacity: 0.8;
}

.scrollTop {
    position: fixed;
    bottom: 0px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #333 !important;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
  }
  
  .scrollTop:hover{
    opacity: 1;
    background-color: #35baf2 !important;
  }

  .scrollTop:active{
    background-color: #35baf2 !important;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

/***************************
**********ADMIN*************
***************************/

.admin-login, .admin-edit-staff {
    height: 100vh;
}
.admin-menu .nav-item a {
    cursor: pointer;
}
